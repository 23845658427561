<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p>
        Click the buttons below to complete the <b>condensed</b> electron configuration for each of
        the following atoms.
      </p>
      <p>
        a) The
        <chemical-ion :symbol="ecElement1" charge="2+" />
        ion
      </p>

      <electron-configuration-input
        v-model="inputs.input1"
        :max-n="4"
        :max-occupation="10"
        :orbital-types="['s', 'p', 'd']"
        prefix="\mathrm{[Ar]}"
        class="pb-5"
      />

      <p>
        b) The
        <chemical-ion :symbol="ecElement2" charge="2+" />
        ion
      </p>
      <electron-configuration-input
        v-model="inputs.input2"
        :max-n="4"
        :max-occupation="10"
        :orbital-types="['s', 'p', 'd']"
        prefix="\mathrm{[Ar]}"
        class="pb-0"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ElectronConfigurationInput from '../inputs/ElectronConfigurationInput';
import ChemicalIon from '../displayers/ChemicalIon';

export default {
  name: 'Question28',
  components: {
    ElectronConfigurationInput,
    ChemicalIon,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: [],
        input2: [],
      },
    };
  },
  computed: {
    ecPosition1() {
      return this.taskState.getValueBySymbol('ecint1').content;
    },
    ecElement1() {
      if (this.ecPosition1.value === 0) {
        return 'Fe';
      } else if (this.ecPosition1.value === 1) {
        return 'Co';
      } else if (this.ecPosition1.value === 2) {
        return 'Ni';
      } else if (this.ecPosition1.value === 3) {
        return 'Cu';
      }
    },
    ecPosition2() {
      return this.taskState.getValueBySymbol('ecint2').content;
    },
    ecElement2() {
      if (this.ecPosition2.value === 0) {
        return 'Zn';
      } else if (this.ecPosition2.value === 1) {
        return 'Cr';
      } else if (this.ecPosition2.value === 2) {
        return 'V';
      } else if (this.ecPosition2.value === 3) {
        return 'Mn';
      }
    },
  },
};
</script>
